$bg-width: 186px;
$bg-height: 178px;
.modal{
    .modal-box:before{
        content:'';
        z-index: -1;
        background-image: url('../../../public/img/bg-script-card.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        position: absolute;
        left: 0;
        bottom: 0;
        width: $bg-width;
        height: $bg-height;
        transform: rotate(90deg);
    }
}